<template>
  <div>
    <v-card-text>
      <v-textarea
        outlined
        height="3"
        outline
        :placeholder="$t('message.add_comments')"
        v-model="commentContents"
      ></v-textarea>
    </v-card-text>
    <v-card-actions style="margin-top:-40px" class="mr-2">
      <v-spacer></v-spacer>
      <v-btn v-if="!commentId" elevation="0" small color="primary" @click="addUpdateComments()">{{$t('message.add')}}</v-btn>
    </v-card-actions>
    <v-card-text v-if="comments.length > 0">
      <template>
        <template v-for="(c) in comments">
          <v-card :key="c._id" outlined class="mt-1">
            <v-list-item>
              <v-list-item-avatar>
                  <img :src="userImg(c.user.userImageSmall)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{c.user.displayName}}</v-list-item-title>
                <v-list-item-subtitle>{{ $d(new Date(c.created), 'month_day_year', $i18n.locale) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text v-html="c.contents" class="mb-0"></v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-1" v-if="c.user._id == $store.state.user._id">
              <v-btn small fab icon @click="showEditDialog(c)">
                <v-icon>mdi-pencil-box-outline</v-icon>
              </v-btn>
              <v-btn
                small
                fab
                icon
                @click="showDeleteDialog(c._id)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>   
              </v-btn>
              <v-spacer></v-spacer>
              <span v-if="c.updatedBy" class="text-caption">
                {{$t('message.edited_by')}}:
                {{c.updatedBy.displayName}}
                <span
                  style="color:gray"
                >{{ $d(new Date(c.updated), 'month_day_year', $i18n.locale) }}</span>
              </span>
            </v-card-actions>
          </v-card>
        </template>
      </template>
    </v-card-text>
    <v-dialog v-model="deleteCommentDialog" max-width="90%">
      <v-card class="pt-2">
        <v-card-text class="mt-4">
          <h2>{{$t('message.are_you_sure')}}</h2>
        </v-card-text>
        <v-card-actions>
          <v-btn elevation="0" color="error" @click="addUpdateComments(true)">{{ $t('message.delete') }}</v-btn>
          <v-btn
            class="outlined-btn" elevation="0"
            @click.stop="deleteCommentDialog=false; commentId=null"
          >{{ $t('message.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCommentDialog" max-width="70%">
      <v-card class="pa-2">
        <v-card-text class="mt-6">
          <v-textarea height="3" outlined v-model="editCommentContents"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" elevation="0" @click="addUpdateComments()">{{ $t('message.edit') }}</v-btn>
          <v-btn
            class="outlined-btn" elevation="0"
            @click.stop="editCommentDialog=false; commentId=null"
          >{{ $t('message.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AffiliateService from "@/services/AffiliateService";

export default {
  props: ["data","hideButtons","referralAffiliate","view"],
  data() {
    return {
      dataId: null,
      commentId: null,
      comments: [],
      commentContents: null,
      editCommentContents: null,
      editCommentDialog: false,
      deleteCommentDialog: false,
    };
  },
  created() {
    if (this.data) {
      this.dataId = this.data._id;
      if (this.data.comments && this.data.comments.length > 0){
        this.comments = this.data.comments;
        this.comments.reverse();
      }
    }
  },
  methods: {
    showEditDialog(comment) {
      this.editCommentDialog = true;
      this.commentId = comment._id;
      this.editCommentContents = comment.contents;
    },
    showDeleteDialog(id) {
      this.deleteCommentDialog = true;
      this.commentId = id;
    },
    async addUpdateComments(removeComment) {
      if (
        removeComment ||
        (this.commentContents && this.commentContents != "") ||
        (this.editCommentContents && this.editCommentContents != "")
      ) {
        const postData = {
          delete: removeComment,
          commentId: this.commentId,
          affiliateId: this.affiliateId,
          dataId: this.dataId,
          contents: this.commentContents
            ? this.commentContents
            : this.editCommentContents
        };
        const response = await AffiliateService.addEditComment(this.referralAffiliate, postData);
        this.comments = response.comments.reverse();
        this.showAlert("success", this.$t("message.success"));
        this.editCommentDialog = false;
        this.deleteCommentDialog = false;
        this.editCommentContents = null;
        this.commentContents = null;
        this.$emit('update-count', this.comments);
      }
    }
  },
  computed: {}
};
</script>

<style scoped>
</style>
