<template>
  <v-sheet class="fill-height" :rounded="isSmallerDisplay() ? '' : 'lg'">
    <div class="text-left pt-2 pb-2 pt-2">
      <v-row v-if="isSmallerDisplay()" style="margin-top:6px">
        <v-col v-if="$store.state.user.academyId" class="text-center">
          <v-btn fab color="primary" outlined small @click="goToAcademy($store.state.user.academyId)">
            <v-icon>mdi-bank-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="academyAddress" class="text-center">
          <v-btn color="primary" outlined small fab @click="goToDirections(academyAddress)">
            <v-icon size="20">mdi-directions</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-center">
          <v-btn color="primary" outlined small fab @click="loadAcademyMembers()">
            <v-icon size="20">mdi-account-group-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-center">
          <v-btn color="primary" outlined small fab @click="navigateTo({ name: 'viewactivesyllabus' });">
            <v-icon size="20">mdi-school-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="alerts" class="text-center">
          <v-badge overlap color="primary" :content="alerts.length">
            <v-btn outlined color="primary" fab small @click="alertsDialog = true">
              <v-icon size="20">mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
        </v-col>
      </v-row>
      <div v-else class="pa-2">
        <v-row v-if="$store.state.user.academyId || academyAddress">
          <v-col v-if="$store.state.user.academyId">
            <v-btn block v-if="$store.state.user.academyId" color=" primary" outlined small
              @click="goToAcademy($store.state.user.academyId)">
              <v-icon size="20" class="mr-1">mdi-bank-outline</v-icon>{{ $t("message.academy_page") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block color="primary" outlined small @click="loadAcademyMembers()">
              <v-icon size="20" class="mr-1">mdi-account-group-outline</v-icon>
              {{ $t("message.members") }}
            </v-btn>
          </v-col>
          <v-col v-if="academyAddress">
            <v-btn block v-if="academyAddress" color="primary" outlined small @click="goToDirections(academyAddress)">
              <v-icon size="20" class="mr-1">mdi-directions</v-icon>
              {{ $t("message.take_me_there") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block color="primary" outlined small @click="navigateTo({ name: 'viewactivesyllabus' });">
              <v-icon size="20" class="mr-1">mdi-school-outline</v-icon> {{ $t("message.academy_programs") }}
            </v-btn>
          </v-col>
          <v-col v-if="alerts">
            <v-btn block color="primary" outlined small @click="alertsDialog = true">
              <v-icon size="20" class="mr-1">mdi-bell-badge-outline</v-icon> {{ $t("message.alerts") }}
              ({{alerts.length}})
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-tabs v-model="activeTab" show-arrows>
        <v-tab :key="'notes'" @click="clickTab(0)">
          <v-icon class="mr-1">mdi-text-box-multiple-outline</v-icon>
          {{ $t("message.notes") }}
        </v-tab>
        <v-tab :key="'payments'" @click="clickTab(1)">
          <v-icon class="mr-1">mdi-cash-100</v-icon>
          {{ $t("message.payments") }}
        </v-tab>
        <v-tab :key="'documents'" @click="clickTab(2)">
          <v-icon class="mr-1">mdi-draw-pen</v-icon>
          {{ $t("message.documents") }}
        </v-tab>
        <v-tab-item :key="'notes'">
          <v-card elevation="0" class="mt-2">
            <v-card-text class="pb-0">
              <v-autocomplete v-model="tagFilterClassNotes" :items="classNoteTags" :label="$t('message.filter_by_tag')"
                clearable dense outlined></v-autocomplete>
            </v-card-text>
          </v-card>
          <v-sheet class="mt-2 pa-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
            <v-expansion-panels flat>
              <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'"
                v-for="(n, index) in classNotes" :key="index + n._id">
                <v-expansion-panel-header class="text-left">
                  <div>
                    <v-icon size="20" v-if="n.video">mdi-play-circle-outline</v-icon>
                    <v-icon size="20" v-else-if="n.videoLink">mdi-open-in-new</v-icon>
                    <v-icon size="20" v-else>mdi-text-box-outline</v-icon>
                    <span class="ml-2">{{ truncate(n.title, 50) }}</span>
                    <span v-if="n.date" class="text-caption">
                      {{ $d(new Date(n.date), 'month_day_year', $i18n.locale) }}
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-title class="text-subtitle-2" v-if="n.tags && n.tags.length > 0">
                      <template class="ml-1" v-for="(t, index2) in n.tags">
                        <v-chip class="mr-1" x-small :key="n._id + 'tag' + index2">{{ t }}</v-chip>
                      </template>
                    </v-card-title>
                    <v-card-text v-html="n.notes" class="page-description"></v-card-text>
                    <v-responsive class="pa-0" v-if="n.video && n.video.ready">
                      <video-display :video="n.video" :skip-autoplay="true"></video-display>
                    </v-responsive>
                    <v-responsive v-else-if="n.videoLink" style="text-align:center">
                      <iframe v-if="getYouTubeId(n.videoLink)" width="100%" height="315"
                        :src="'https://www.youtube.com/embed/'+getYouTubeId(n.videoLink)" frameborder="0"
                        allowfullscreen></iframe>
                    </v-responsive>
                    <div v-if="n.flowchart" class="ma-2">
                      <div class="text-center text-subtitle-1">
                        {{ n.flowchart.name }}
                      </div>
                      <div>
                        <v-btn block color="primary" outlined elevation="0" @click="viewFlowchart(n.flowchart._id)">
                          <v-icon class="mr-1">mdi-sitemap-outline</v-icon>
                          {{ $t('message.view_flowchart') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div v-if="viewMoreClassNotes">
              <v-btn color="primary" @click="getClasNotes(true)">{{
                $t("message.view_more")
                }}</v-btn>
            </div>
          </v-sheet>
        </v-tab-item>
        <v-tab-item :key="'payments'" class="pa-2">
          <div v-if="familyMembers && familyMembers.length > 0">
            <v-select @change="getFamilyMemberData()" v-model="selectedFamilyMember" :items="familyMembers"
              item-text="name" item-value="userId" :label="$t('message.select_one')" clearable outlined dense>
            </v-select>
          </div>
          <v-skeleton-loader v-if="loadingPayments" type="list-item, divider, list-item"></v-skeleton-loader>
          <v-expansion-panels class="mt-2" flat>
            <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
              <v-expansion-panel-header class="text-left">
                <div>
                  <v-icon v-if="cardOnFile">mdi-credit-card-check-outline</v-icon>
                  <v-icon v-else>mdi-credit-card-outline</v-icon>
                  {{ $t('message.card_on_file') }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="cardOnFile">
                <PaymentMethod :height="60" :payment-method="cardOnFile" :hide-name="true"></PaymentMethod>
                <v-card-actions>
                  <v-btn block @click="updateCardOnFile()" elevation="0" small color="primary">{{ $t('message.update')
                    }}</v-btn>
                </v-card-actions>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-else>
                <v-card-actions>
                  <v-btn block @click="updateCardOnFile()" elevation="0" small color="primary">
                    <v-icon class="mr-1">mdi-credit-card-plus-outline</v-icon>
                    {{ $t('message.add_card_on_file')}}</v-btn>
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'"
              v-if="subscriptions && subscriptions.length > 0">
              <v-expansion-panel-header class="text-left">
                <div>
                  <v-icon>mdi-wallet-membership</v-icon>
                  {{ $t('message.memberships') }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <template v-for="(i, index) in subscriptions">
                    <v-list-item three-line :key="'subscription_'+index"
                      @click="navigateTo({ name: 'transaction', params: { transactionId: i._id } })">
                      <v-list-item-action>
                        <v-tooltip v-if="i.expired" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge v-bind="attrs" v-on="on" avatar bordererd overlap>
                              <template v-slot:badge>
                                <v-icon size="20">mdi-clock-outline</v-icon>
                              </template>
                              <v-icon v-if="i.subscription" :color="getStatusColor(i, i.subscriptionStatus)"
                                size="25">mdi-sync
                              </v-icon>
                              <v-icon v-else :color="getStatusColor(i, i.subscriptionStatus)"
                                size="25">mdi-cash-100</v-icon>
                            </v-badge>
                          </template>
                          <span>{{$t('message.expired')}}</span>
                        </v-tooltip>
                        <v-icon v-else-if="i.subscription" :color="getStatusColor(i, i.subscriptionStatus)"
                          size="25">mdi-sync
                        </v-icon>
                        <v-icon v-else :color="getStatusColor(i, i.subscriptionStatus)" size="25">
                          mdi-cash-100
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon v-if="i.coupon && i.coupon.discountedTotal > 0" size="20"
                            :color="themeColor('primary')">mdi-tag-outline</v-icon>
                          <span v-if="i.product">{{i.product.name}}</span>
                          <span v-else-if="i.items && i.items[0]">{{ i.items[0].name}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="i.time">{{ $d(new Date(i.time), 'long', $i18n.locale) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="i.alternateAcademy">
                          <v-avatar size="20" v-if="i.alternateAcademy.image">
                            <img :src="userImg(i.alternateAcademy.image)" />
                          </v-avatar>
                          <span v-if="i.product">{{getIntervalDisplay(i.product.interval) }}</span> - <span
                            class="primary--text">{{
                            i.alternateAcademy.name }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="i.product && i.product.interval">{{
                          getIntervalDisplay(i.product.interval) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{(i.currencySymbol ? i.currencySymbol : '$')+getTransactionTotal(i)}}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="index + 1 < subscriptions.length" :key="index"></v-divider>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'"
              v-if="payments && payments.length > 0">
              <v-expansion-panel-header class="text-left">
                <div>
                  <v-icon class="mr-1">mdi-cash-100</v-icon>
                  {{ $t('message.payments') }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <template v-for="(i, index) in payments">
                    <v-list-item :key="'payment_'+index"
                      @click="navigateTo({ name: 'transaction', params: { transactionId: i._id } })">
                      <v-list-item-action>
                        <v-icon v-if="!i.sessionId && i.amount < 0" color="primary" size="25">mdi-cash-100</v-icon>
                        <v-icon v-else-if="!i.sessionId" :color="getStatusColor(i)" size="25">mdi-cash-100</v-icon>
                        <v-icon v-else :color="getStatusColor(i)" size="25">mdi-credit-card-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div v-if="i.product">
                            <v-icon v-if="i.coupon && i.coupon.discountedTotal > 0" size="20"
                              :color="themeColor('primary')">mdi-tag-outline
                            </v-icon>
                            {{i.product.name}}
                          </div>
                          <div v-else-if="i.items">
                            <v-icon v-if="i.coupon && i.coupon.discountedTotal > 0" size="20"
                              :color="themeColor('primary')">mdi-tag-outline
                            </v-icon>
                            <span v-if="i.items.length == 1">{{i.items[0].name ? i.items[0].name : (i.items[0].product
                              ?
                              i.items[0].product.name : 'Product')}}</span>
                            <span v-else>{{i.items.length}} {{ $t('message.items') }}</span>
                          </div>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="i.time">{{ $d(new Date(i.time), 'long', $i18n.locale) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{(i.currencySymbol ? i.currencySymbol : '$')+getTransactionTotal(i)}}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="index + 1 < payments.length" :key="'payments_index_'+index"></v-divider>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <v-tab-item :key="'documents'">
          <v-card elevation="0" class="mt-2 pa-2">
            <div v-if="familyMembers && familyMembers.length > 0">
              <v-select @change="getFamilyMemberData()" v-model="selectedFamilyMember" :items="familyMembers"
                item-text="name" item-value="userId" :label="$t('message.select_one')" clearable outlined dense>
              </v-select>
            </div>
            <v-skeleton-loader v-if="loading" type="list-item, divider, list-item"></v-skeleton-loader>
            <v-list>
              <template v-for="(i, index) in documents">
                <v-list-item :key="'document'+index"
                  @click="navigateTo({ name: 'viewacademydocument', params: { academyDocumentId: i._id } })">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{i.template.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="i.time">{{ $d(new Date(i.time), 'month_day_year', $i18n.locale) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index + 1 < documents.length" :key="index"></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
    <v-dialog v-model="alertsDialog" width="90%">
      <v-card outlined flat>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon small @click="alertsDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <v-list>
            <v-list-item :key="i._id" v-for="(i) in alerts" @click="goToAlert(i)">
              <v-list-item-action>
                <v-btn elevation="0" fab>
                  <v-icon v-if="i.type == 'alert_missing_document'">mdi-file-sign</v-icon>
                  <v-icon v-else-if="i.type == 'alert_no_membership'">mdi-file-sign</v-icon>
                  <v-icon v-else-if="i.type == 'alert_membership_payment'">mdi-file-sign</v-icon>
                  <v-icon v-else-if="i.type == 'alert_expired_membership'">mdi-alert-box-outlinee</v-icon>
                  <v-icon v-else>mdi-clock-time-one-outline</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ i.text }}</v-list-item-title>
                <v-list-item-subtitle class="primary--text" v-if="i.familyMember && i.familyMember.displayName">{{
                  i.familyMember.displayName
                  }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ $t("message."+i.type) }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar v-if="i.familyMember && i.familyMember.userImageSmall">
                <img :src="userFile(i.familyMember.userImageSmall)" />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="flowchartDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card outlined class="pa-0">
        <v-app-bar flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="flowchartDialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-app-bar>
        <v-skeleton-loader v-if="loadingFlowchart" type="card, article"></v-skeleton-loader>
        <flowy v-if="flowchartDialog" class="flowchart-container" :nodes="previewNodes">
        </flowy>
      </v-card>
    </v-dialog>
    <v-dialog v-model="membersDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card outlined class="pa-0">
        <v-app-bar flat>
          {{ $t('message.members') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="membersDialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-app-bar>
        <v-skeleton-loader v-if="loadingMembers"
          type="list-item-avatar, list-item-avatar, list-item-avatar"></v-skeleton-loader>
        <div v-else-if="academyMembers && academyMembers.length > 0">
          <div class="pa-2">
            <v-text-field hide-details dense outlined :label="$t('message.filter')"
              v-model="filterMembersValue">
            </v-text-field>
          </div>
          <v-list>
            <template v-for="(u, index) in academyMembersFiltered">
              <v-list-item class="pl-1 pr-1" two-line v-if="!u.hidden" :key="u._id" @click="goToUser(u._id)" ripple>
                <v-list-item-avatar :tile="true">
                  <UserAvatar :user="u" size="28" :small-badge="true" :has-progress="false">
                  </UserAvatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <UserName :user="u" :link="false" :add-hover="false" :hide-flag="true" :avatar="false">
                    </UserName>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <BeltDisplay size="15" class="mr-1" :hide-icon="false" :show-text="u.academyName ? false : true"
                      :belt="u.belt"></BeltDisplay>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="u.canLink">
                  <LinkButton v-if="$store.state.user._id != u._id" :linked="false" :userId="u._id" :small="true">
                  </LinkButton>
                </v-list-item-action>
                <v-list-item-action v-else>
                  <CountryFlag size="35" :avatar="true" :country-code="u.nationality"></CountryFlag>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index + '_academy_member'"></v-divider>
            </template>
          </v-list>
        </div>
        <v-card-text v-else class="text-h3">
          {{ $t('message.no_results') }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>
<script>
import JournalService from "@/services/JournalService";
import AcademyService from "@/services/AcademyService";
import PaymentsService from "@/services/PaymentsService";
import ConnectService from "@/services/ConnectService";
import AcademyDocumentService from "@/services/AcademyDocumentService";
import VideoDisplay from "@/components/displays/VideoDisplay.vue";
import PaymentMethod from "@/components/displays/PaymentMethodDisplay.vue";
import UserAvatar from "@/components/displays/UserAvatarDisplay";
import UserName from "@/components/displays/UserNameDisplay.vue";
import FlowchartNodeView from "@/components/displays/FlowchartNodeView.vue";
import BeltDisplay from "@/components/displays/BeltDisplay";
import LinkButton from "@/components/displays/LinkUserButtonDisplay.vue";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";


export default {
  data() {
    return {
      loading: true,
      managedAcademy: this.$store.state.userManagedAcademy,
      urlTab: this.$route.params.tabId,
      sessionId: this.$route.query.sessionId,
      sessionStatus: this.$route.query.status,
      checkoutType: this.$route.query.type,
      academyId: null,
      activeTab: 0,
      activeDateTab: 0,
      academyName: null,
      academyImage: null,
      classNotes: null,
      alertsDialog: null,
      viewMoreClassNotes: false,
      tagFilterClassNotes: null,
      classNoteTags:[],
      payments: [],
      subscriptions: [],
      documents: [],
      twoWeeksDays:[],
      familyMembers: [],
      isMember: true,
      schedule: null,
      scheduleNotes: null,
      academyAddress: null,
      professors: null,
      upcomingEvents: null,
      selectedFamilyMember: null,
      checkinCode: null,
      alerts: null,
      loadingPayments: false,
      cardOnFile: null,
      paymentsModule: null,
      previewNodes: [],
      flowchartDialog: null,
      loadingFlowchart: false,
      academyMembers: null,
      academyMembersFiltered: null,
      filterMembersValue: null,
      membersDialog: null,
      loadingMembers: false
    };
  },
  components: {
    VideoDisplay,
    PaymentMethod,
    UserAvatar,
    UserName,
    BeltDisplay,
    LinkButton,
    CountryFlag
  },
  mounted(){
    try{
      if(this.urlTab){
        const n = new Number(this.urlTab);
        this.activeTab = n.valueOf();
        this.clickTab(n);
      }
    }catch(err){
      console.log('Invalid tab: ', this.urlTab)
    }
  },
  created() {
    if (this.sessionId && this.sessionStatus == "success") {
      if (this.checkoutType && this.checkoutType == 'update')
        this.completeCOFUpdate();
    }
    if (this.$store.state.user.academyId) {
      AcademyService.academy(this.$store.state.user.academyId, false)
        .then((data) => {
          if (data) {
            this.academyAddress = data.academy.address;
            this.paymentsModule = data.paymentsModule;
          }
        });
    }
    this.getClasNotes(false);
    JournalService.classNotesTags()
      .then((data) => {
        if (data) this.classNoteTags = data.tags;
      });
    this.getTransactions();
    this.getCompletedDocuments();
    this.getAlerts()
    this.getFamilyMembers();
  },
  methods: {
    loadAcademyMembers() {
      this.loadingMembers = true;
      this.membersDialog = true;
      AcademyService.members(this.$store.state.user.academyId)
        .then((data) => {
          if (data) {
            this.academyMembers = data;
            for (let u of this.academyMembers) {
              if (u.linkedBy) {
                let i = u.linkedBy.indexOf(this.$store.state.user._id);
                if (i >= 0) u.alreadyLinked = true;
                else u.alreadyLinked = false;
              }
            }
            this.academyMembersFiltered = this.academyMembers;
            this.loadingMembers = false;
          }
        });
    },
    viewFlowchart(id) {
      this.loadingFlowchart = true;
      this.flowchartDialog = true;
      JournalService.getFlowchart(this.managedAcademy, id)
        .then((data) => {
          if (data && data.flowchart)
            this.loadChartNodesPreviewMode(data.flowchart.nodeIds);
          this.loadingFlowchart = false;
        }).catch((err) => {
          console.log(err)
          this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    loadChartNodesPreviewMode(nodes) {
      this.previewNodes = [];
      let previewNodes = [];
      if (nodes) {
        for (const n of nodes) {
          previewNodes.push(
            {
              id: n.id,
              parentId: n.parentId,
              nodeComponent: FlowchartNodeView,
              type: n.nodeType,
              title: n.title,
              technique: this.getTechniqueTitle(n.nodeType),
              description: n.description,
              adminVideo: n.adminVideo,
              videoUrl: n.videoUrl,
              imageUrl: n.imageUrl,
              flowchartId: this.flowchartId,
            }
          );
        }
        this.previewNodes = previewNodes;
        this.loading = false;
      }
    },
    doNothing() { },
    completeCOFUpdate() {
      PaymentsService.completeCOFUpdate(this.managedAcademy, this.$store.state.user._id, this.sessionId)
        .then(response => {
          if (response) {
            this.showAlert("success", this.$t("message.updated"));
            if (response.cardOnFile)
              this.cardOnFile = response.cardOnFile;
          } else {
            this.showAlert("error", this.$t("message.error_generic"));
          }
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    updateCardOnFile() {
      if (this.paymentsModule == 'infinicept') {
        this.navigateTo({
          name: "inifnicept-checkout",
          params: {
            transactionData: {
              memberId: this.$store.state.user._id,
              managedAcademyId: this.managedAcademy,
              action: 'update-card',
              routeFrom: 'viewmyacademy'
            }
          }
        });
      } else {
        PaymentsService.initUpdateCreditCardSessionMember(this.managedAcademy, this.$store.state.user._id)
          .then(response => {
            const session = response.session;
            this.navigateTo({
              name: "ccupdatemember",
              params: {
                memberId: this.$store.state.user._id
              },
              query: { s: session.id, a: response.stripeAccountId }
            });
          })
          .catch(e => {
            if (e) {
              if (e.response.data && e.response.data.message)
                this.showAlert("error", e.response.data.message);
              else
                this.showAlert("error", this.$t("message.error_generic"));
            }
          });
      }
    },
    getMemberData() {
      if (this.managedAcademy) {
        AcademyService.getMemberData(this.managedAcademy, this.$store.state.user._id)
          .then((data) => {
            if (data.cardOnFile) 
              this.cardOnFile = data.cardOnFile;
          });
      }
    },
    getFamilyMembers() {
      if (this.managedAcademy) {
        AcademyService.academyFamily(this.managedAcademy)
          .then((data) => {
            if (data && data.members && data.members.length > 0) {
              const familyMembers = [{
                name: this.$t('message.you'),
                userId: null
              }];
              this.familyMembers = familyMembers.concat(data.members);
            }
          });
      }
    },
    getFamilyMemberData() {
      this.getCompletedDocuments()
      this.getTransactions();
    },
    goToAlert(i) {
      if (i.type == 'alert_missing_document') {
        if(i.familyMember)
          this.navigateTo({
            name: "viewacademydocumentnotification",
            params: { academyDocumentId: i.objectId, notificationId: 'alert' },
            query: { doid: i.familyMember._id, doname: i.familyMember.displayName }
          });
        else 
          this.navigateTo({
            name: "viewacademydocumentnotification",
            params: { academyDocumentId: i.objectId, notificationId: 'alert' },
          });
      } else if (i.type == 'alert_membership_payment') {
        this.navigateTo({ name: 'transaction', params: { transactionId: i.objectId } })
      }
    },
    getAlerts() {
      ConnectService.getMyAcademyAlerts()
        .then(response => {
          if (response.alerts && response.alerts.length) {
            this.alerts = response.alerts;
          }
        });
    },
    getCompletedDocuments(){
      this.loading = true;
      AcademyDocumentService.completedDocuments(this.managedAcademy, this.selectedFamilyMember)
        .then(response => {
          this.documents = response.documents;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
        });
    },
    getTransactions() {
      this.loadingPayments = true;
      PaymentsService.transactions(this.managedAcademy, this.selectedFamilyMember)
        .then(response => {
          this.payments = response.payments;
          this.subscriptions = response.subscriptions;
          this.loadingPayments = false;
        })
        .catch(e => {
          console.log(e);
        });
    },
    clickTab(tab){
      if(tab != this.activeTab){
        this.navigateTo({
          name: "myacademytab",
          params: { tabId: tab },
        });
      }
      if (tab == 1) {
        this.getMemberData()
      }
    },
    getYouTubeId(url){
      if(url){
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
          ? match[2]
          : null;
      }
      return null;
    },
    clearFilterClassNotes() {
      this.tagFilterClassNotes = null;
      this.getClasNotes();
    },
    async getClasNotes(append) {
      try {
        if (!this.searchingClassNotes) {
          this.searchingClassNotes = true;
          this.viewMoreClassNotes = false;
          if (!append) {
            this.index = 0;
            this.classNotes = [];
          }
          var data = {};
          if (this.tagFilterClassNotes) data.tags = [this.tagFilterClassNotes];
          JournalService.classNotes(this.index, data)
            .then((data) => {
              if (data) {
                this.classNotes = this.classNotes.concat(data.classNotes);
                this.searchingClassNotes = false;
                this.index = this.index + data.count;
                if (data.count >= this.searchesLimit) this.viewMoreClassNotes = true;
              }
            })
            .catch(() => {
              this.searchingClassNotes = false;
            });
        }
      } catch (err) {
        console.log(err.toString());
      }
    },
    filterMembers(value) {
      if (value == "") {
        this.academyMembersFiltered = this.members;
      } else {
        value = value.toLowerCase();
        if (this.academyMembers) {
          var filtered = [];
          for (const m of this.academyMembers) {
            const displayName = m.fName.toLowerCase() + ' ' + m.lName.toLowerCase();
            if (
              m.fName.toLowerCase().indexOf(value) >= 0 ||
              m.lName.toLowerCase().indexOf(value) >= 0 ||
              displayName.indexOf(value) >= 0
            ) {
              filtered.push(m);
            }
          }
          this.academyMembersFiltered = filtered;
        }
      }
    },
  },
  watch: {
    filterMembersValue: {
      handler: function (newValue) {
        this.filterMembers(newValue);
      },
    },
    tagFilterClassNotes: {
      handler: function (newValue) {
        if (newValue) {
          this.getClasNotes();
        }else{
          this.clearFilterClassNotes();
        }
      },
    },
  },
};
</script>

<style scoped>
.flowchart-container {
  padding: 5px;
  border-radius: 5px;
  background-color: #8866ff10;
  margin-bottom: 8rem !important;
}
</style>