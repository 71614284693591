import Api from "@/services/Api";

export default {
  async search(term) {
    const response = await Api().get(
      "utils/search?term=" + encodeURIComponent(term)
    );
    return response.data;
  },
  async searchPostTags(term, managedAcademy) {
    const ma = managedAcademy ? "&ma=" + managedAcademy : "";
    const response = await Api().get(
      "utils/tags/search?term=" + encodeURIComponent(term) + ma
    );
    return response.data;
  },
  async searchLocations(term) {
    const response = await Api().get(
      "utils/search?term=" +
        encodeURIComponent(term) +
        "&types=event,academy&limit=5"
    );
    return response.data;
  },
  async getHelp(id) {
    const response = await Api().get("utils/help/" + id);
    return response.data;
  },
  async getAllHelp() {
    const response = await Api().get("utils/help/all");
    return response.data;
  },
  async sports() {
    const response = await Api().get("utils/sports");
    return response.data;
  },
  async addHelp(data) {
    const response = await Api().post("utils/help", data);
    return response.data;
  },
  async updateHelp(id, data) {
    const response = await Api().patch("utils/help/" + id, data);
    return response.data;
  },
};
