<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
    <div v-if="isAppAdmin(this.$store.state.user._id)">
      <v-card elevation="0">
        <v-card-title class="clickable">
          Traverse Lineage Tree
        </v-card-title>
        <div class="text-center" v-if="loading">
          <v-progress-circular
            indeterminate
            size="100"
            color="success"
          >
            <IconLineage size="30" :color="themeColor('success')"></IconLineage>
          </v-progress-circular>
          <div class="text-body-2">{{ $t("message.loading_lineage_tree") }}</div>
        </div>
        <v-card-text v-else class="text-left">
          <div v-if="false">
            <v-autocomplete
              dense
              outlined
              :items="treeNodes"
              label="Search Tree"
              v-model="selectedTreeName"
              item-text="name"
              item-value="_id"
              :clearable="true"
              hint="Search Node"
              :loading="loadingTreeNodes"
              hide-no-data
              :search-input.sync="searchTree"
              class="pa-0 autocomplete"
              :disable-lookup="true"
              style="margin-top:5px"
            >
              <template slot="no-data">
                <v-list-item>
                  <span class="subheading">{{ $t('message.no_data_matching') }}</span>
                </v-list-item>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-avatar v-if="data.item && data.item.user">
                  <img :src="userImg(data.item.user.userImageSmall)" />
                </v-list-item-avatar>
                <v-list-item-actioZZ v-else>
                  <CheckIcon class="hero-icon"></CheckIcon>
                </v-list-item-actioZZ>
                <v-list-item>
                  <v-list-item-content v-if="data.item">{{data.item.name}}</v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div v-if="tree">
            <ul>
              <tree-item
                style="list-style: none"
                class="item"
                :edit-children="true"
                :item="tree"
                @make-folder="makeFolder"
                @add-item="addItem"
                @edit-node="disaplayEditNodeDialog"
                v-on:node_clicked="openAddChildDialog"
              ></tree-item>
            </ul>
          </div>
          <div v-else style="text-align:center">
            <circles-loader></circles-loader>
          </div>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-card class="mt-2" elevation="0">
        <v-card-title>
          Orphan Nodes
        </v-card-title>
            <v-card-text>
              <v-autocomplete
                :items="orphanNodes"
                v-model="selectedOrphanName"
                item-text="name"
                item-value="_id"
                :clearable="true"
                label="Search Node"
                :loading="loadingOrphanNodes"
                dense
                outlined
                hide-no-data
                :search-input.sync="searchOrphans"
                class="pa-0 autocomplete mt-2"
                :disable-lookup="true"
              >
                <template slot="no-data">
                  <v-list-item>
                    <span class="subheading">{{ $t('message.no_data_matching') }}</span>
                  </v-list-item>
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-avatar v-if="data.item && data.item.user">
                    <img :src="userImg(data.item.user.userImageSmall)" />
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else>
                    <CheckIcon class="hero-icon"></CheckIcon>
                  </v-list-item-avatar>
                  <v-list-item>
                    <v-list-item-content v-if="data.item">{{data.item.name}}</v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-card-text>
            <v-card-text v-if="orphans && orphans.length > 0">
              <div v-if="orphans && orphans.length > 0">
                <ul style="list-style: none;" v-for="(t, index) in orphans" :key="'tree_'+index">
                  <tree-item
                    class="item"
                    :hide-edit="true"
                    v-on:node_clicked="openAddParentDialog"
                    :item="t"
                    @make-folder="makeFolder"
                    @add-item="addItem"
                  ></tree-item>
                </ul>
              </div>
            </v-card-text>
            <v-card-text v-if="loadingOrphans" style="text-align:center">
              <circles-loader></circles-loader>
            </v-card-text>
      </v-card>
      <v-dialog v-model="addChildDialog" max-width="60%">
        <v-card outlined>
          <v-card-title class="text-h5">
            Add Child to node
            <span style="color:red">(be careful!)</span>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              :items="allNodes"
              v-model="selectedChild"
              item-text="name"
              item-value="_id"
              label="Pick existing node"
              :clearable="true"
              hint="Search Node"
              :loading="loadingTreeNodes"
              dense outlined
              hide-no-data
              :search-input.sync="searchOrphansTree"
              class="pa-0 autocomplete"
              :disable-lookup="true"
              style="margin-top:5px"
            >
              <template slot="no-data">
                <v-list-item>
                  <span class="subheading">{{ $t('message.no_data_matching') }}</span>
                </v-list-item>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-avatar v-if="data.item && data.item.user">
                  <img :src="userImg(data.item.user.userImageSmall)" />
                </v-list-item-avatar>
                <v-list-item-avatar v-else>
                  <CheckIcon class="hero-icon"></CheckIcon>
                </v-list-item-avatar>
                <v-list-item>
                  <v-list-item-content v-if="data.item">{{data.item.name}}</v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-text>or</v-card-text>
          <v-card-text>
            <v-text-field outlined dense v-model="childName" label="Add New Child Node"></v-text-field>
            <v-text-field outlined dense v-model="nickname" label="Nickname"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click.stop="addChild()">{{ $t('message.add') }}</v-btn>
            <v-btn color="secondary" @click.stop="addChildDialog=false">{{ $t('message.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addParentDialog" max-width="60%">
        <v-card outlined>
          <v-card-title>
            <h3>
              Add Parent to node
              <span style="color:red">(be careful!)</span>
            </h3>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              :items="allNodes"
              v-model="selectedParent"
              item-text="name"
              item-value="_id"
              label="Pick existing node"
              :clearable="true"
              hint="Search Node"
              :loading="loadingTreeNodes"
              dense outlined
              hide-no-data
              :search-input.sync="searchTree"
              class="pa-0 autocomplete"
              :disable-lookup="true"
              style="margin-top:5px"
            >
              <template slot="no-data">
                <v-list-item>
                  <span class="subheading">{{ $t('message.no_data_matching') }}</span>
                </v-list-item>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-avatar v-if="data.item && data.item.user">
                  <img :src="userImg(data.item.user.userImageSmall)" />
                </v-list-item-avatar>
                <v-list-item-action v-else>
                  <CheckIcon class="hero-icon" :color="themeColor('success')"></CheckIcon>
                </v-list-item-action>
                <v-list-item>
                  <v-list-item-content v-if="data.item">{{data.item.name}}</v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-text>or</v-card-text>
          <v-card-text>
            <v-text-field outlined dense v-model="parentName" label="Add New Parent Node"></v-text-field>
            <v-text-field outlined dense v-model="nickname" label="Nickname"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click.stop="addParent()">{{ $t('message.add') }}</v-btn>
            <v-btn color="secondary" @click.stop="addParentDialog=false">{{ $t('message.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="nodeToEdit" v-model="editNodeDialog" max-width="60%">
        <v-card outlined>
          <v-card-title>Edit Node</v-card-title>
          <v-card-text>
            <v-text-field outlined dense v-model="nodeToEdit.name" label="Name"></v-text-field>
            <v-text-field outlined dense v-model="nodeToEdit.nickname" label="Nickname (Optional)"></v-text-field>
            <v-text-field outlined dense v-model="nodeToEdit.userId" label="User Id (Optional)"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click.stop="editNode()">{{ $t('message.edit') }}</v-btn>
            <v-btn color="secondary" @click.stop="editNodeDialog=false">{{ $t('message.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <h2>You are not welcome here.</h2>
      <div>
        <icon-master color="error" :size="200" />
      </div>
    </div>
  </v-sheet>
</template>

<script>
import BjjlinkAdminService from "@/services/BjjlinkAdminService";
import LineageService from "@/services/LineageService";
import IconMaster from "@/components/displays/icons/IconMaster.vue";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";
import TreeItem from "@/components/displays/TreeItemAdmin.vue";
import IconLineage from "@/components/displays/icons/IconLineage";

import { 
  CheckIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      vm: this,
      loading: true,
      loadingOrphanNodes: false,
      loadingTreeNodes: false,
      tree: null,
      orphans: null,
      searchOrphans: null,
      searchTree: null,
      searchOrphansTree: null,
      selectedOrphanName: null,
      selectedTreeName: null,
      orphanNodes: null,
      treeNodes: null,
      selectedNode: null, 
      nodeToEdit: null,
      loadingOrphans: false,
      childToAdd: null,
      parentToAdd: null,
      addParentDialog: false,
      addChildDialog: false,
      selectedParent: null,
      selectedChild: null,
      childName: null,
      nickname: null,
      parentName: null,
      allNodes: [],
      editNodeDialog: false,
    };
  },
  components: {
    IconMaster,
    CirclesLoader,
    TreeItem,
    CheckIcon,
    IconLineage
  },
  created() {
    this.loadAll();
  },
  methods: {
    loadAll(){
      this.getTree("root", true);
      this.getOrphanNames();
      this.getTreeNodes();
    },
    disaplayEditNodeDialog(node){
      this.nodeToEdit = node;
      this.editNodeDialog =true;
    },
    editNode(){
      BjjlinkAdminService.editNode(this.nodeToEdit._id, this.nodeToEdit)
        .then(() => {
          this.nodeToEdit = null;
          this.editNodeDialog =false;
          this.showAlert("success", "All good!");
        })
        .catch(err => {
          if(err.response && err.response.data.message)
            this.showAlert("error", "Yoo! ", err.response.data.message);
          else
            this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    openAddChildDialog(id) {
      this.selectedNode = id;
      this.addChildDialog = true;
    },
    openAddParentDialog(id) {
      this.selectedNode = id;
      this.addParentDialog = true;
    },
    addChild() {
      const data = {
        nodeId: this.selectedNode,
        childId: this.selectedChild,
        childName: this.childName,
        nickname: this.nickname,
      };
      BjjlinkAdminService.addChildToNode(this.selectedNode, data)
        .then(data => {
          this.selectedNode = null;
          this.addChildDialog = false;
          if (data) {
            this.selectedChild = null;
            this.loadAll();
            //this.getOrphans();
            this.childName = null;
            this.selectedTreeName = null;
            this.nickname = null;
          }
        })
        .catch(err => {
          if(err.response && err.response.data.message)
            this.showAlert("error", "Yoo! ", err.response.data.message);
          else
            this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    addParent() {
      BjjlinkAdminService.addParentToNode(this.selectedNode, {
        parentId: this.selectedParent,
        parentName: this.parentName,
        nickname: this.nickname
      })
        .then(data => {
          this.selectedNode = null;
          this.addParentDialog = false;
          if (data) {
            this.loadAll();
            this.showAlert("success", "Yoo! ", "You did it :)");
            this.selectedParent = null;
            this.parentName = null;
            this.nickname = null;
          }
        })
        .catch(err => {
          if(err.response && err.response.data.message)
            this.showAlert("error", "Yoo! ", err.response.data.message);
          else
            this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    getTreeNodes() {
      LineageService.treeNodes()
        .then(data => {
          if (data) {
            this.treeNodes = data.nodes;
            this.allNodes = data.nodes;
            if (this.orphanNodes) {
              this.allNodes = this.allNodes.concat(this.orphanNodes);
            }
          }
        });
    },
    getOrphanNames() {
      this.loadingOrphanNodes = true;
      BjjlinkAdminService.orphanNodes()
        .then(data => {
          if (data) {
            this.orphanNodes = data.nodes;
            this.allNodes = data.nodes;
            if (this.treeNodes) {
              this.allNodes = this.allNodes.concat(this.treeNodes);
            }
            this.loadingOrphanNodes = false;
          }
        });
    },
    getOrphans(node) {
      this.loadingOrphans = true;
      this.orphans = null;
      if (!this.orphans) {
        BjjlinkAdminService.lineageTrees(node ? node : null)
          .then(data => {
            if (data) {
              this.orphans = data.trees;
              this.loadingOrphans = false;
            }
          });
      }
    },
    getTree(nodeId, root) {
      this.loading = true;
      this.tree = null;
      LineageService.children(nodeId, root).then((data) => {
        if (data) {
          if (root) this.tree = data.root;
          this.loading = false;
        }
      });
    },
    makeFolder(item) {
      this.addItem(item);
    },
    addItem(item) {
      item.children.push({
        name: "new stuff"
      });
    },
  },
  mounted() {},
  computed: {},
  watch: {
    selectedOrphanName(val) {
      this.orphans = null;
      this.getOrphans(val);
    },
    selectedTreeName(val) {
      if (!val) val = "root";
      this.trees = null;
      this.getNodes(val);
    }
  }
};
</script>

<style scoped>
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: dot;
}
</style>
