  <template>
    <v-sheet class="pa-1">
      <div v-if="loadingHome" class="text-center pa-3">
        <v-progress-circular
          :size="160"
          color="primary"
          indeterminate
          :width="10"
        ></v-progress-circular>
        <div class="text-h5">
          Refreshing this month's data...
        </div>
        <div class="text-h6">
          Stay tuned... Mate!
        </div>
      </div>
      <div v-else>
        <div v-if="invalidUser" class="text-center">
          <div class="text-h6">
            We know you should not be here
          </div>
          <IconMaster size="200"></IconMaster>
        </div>
        <div v-else-if="data">
          <v-tabs v-model="activeTab" show-arrows>
            <v-tab :key="'dashboard'" ripple>
              <v-icon class="mr-1">mdi-view-dashboard-outline</v-icon>  
              Dashboard
            </v-tab>
            <v-tab :key="'data'" ripple><v-icon class="mr-1">mdi-table</v-icon>Data</v-tab>
            <v-tab :key="'referrals'" ripple @click="getReferralData(true)"><v-icon class="mr-1">mdi-account-tie-voice-outline</v-icon>Referrals</v-tab>
            <v-tabs-items v-model="activeTab">
              <v-tab-item :key="'dashboard'" class="pa-2">
                <v-row>
                  <v-col>
                    <v-card
                      outlined
                      :height="widgetHeight"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-icon class="mr-1">mdi-flag-outline</v-icon>
                        <b class="mr-1">{{data.countries.length}}</b> Countries w/ Admin+
                        <v-btn class="ml-2" x-small icon @click="countriesDialog=true">
                          <v-icon>mdi-information-slab-circle-outline</v-icon>
                        </v-btn>
                      </v-card-title>
                      <div>
                        <ApexChart v-if="graphOptions.regionsGraph" type="pie" :options="graphOptions.regionsGraph" height="300"
                          :series="data.clientsByRegion.data"></ApexChart>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      outlined
                      :height="widgetHeight"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-icon class="mr-1">mdi-wallet-membership</v-icon>
                        <b class="mr-1">{{data.totalCustomers}}</b> Total Customers
                      </v-card-title>
                      <v-card-text class="text-center pb-0 mb-0">
                        {{ data.newCustomers}}/{{data.currentMonthTargets.newCustomers}} 
                      </v-card-text>
                      <div class="radial-container">
                        <ApexChart v-if="graphOptions.radialCustomers" type="radialBar" :options="graphOptions.radialCustomers" height="300"
                          :series="data.customersData.series"></ApexChart>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      outlined
                      :height="widgetHeight"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-icon class="mr-1">mdi-cash-multiple</v-icon>
                        Revenue by Type
                      </v-card-title>
                      <div>
                        <ApexChart v-if="graphOptions.revenueGraph" type="bar" :options="graphOptions.revenueGraph" height="250"
                          :series="data.revenueByType.series"></ApexChart>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card
                      outlined
                      :height="widgetHeight"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-icon class="mr-1">mdi-bank</v-icon>
                        <b class="mr-1">{{data.inNetworkAcademies}}</b> Total InNetwork Academies
                      </v-card-title>
                      <v-card-text class="text-center pb-0 mb-0">
                        {{ data.newInNetworkAcademies}}/{{data.currentMonthTargets.newInNetworkAcademies}} 
                      </v-card-text>
                      <div class="radial-container">
                        <ApexChart v-if="graphOptions.radialInNetworkAcademies" type="radialBar" :options="graphOptions.radialInNetworkAcademies" height="300"
                          :series="data.inNetworkData.series"></ApexChart>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      outlined
                      :height="widgetHeight"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-icon class="mr-1">mdi-check-decagram-outline</v-icon>
                        <b class="mr-1">{{data.verifiedInNetworkAcademies}}</b> Total Verified InNetwork Academies
                      </v-card-title>
                      <v-card-text class="text-center pb-0 mb-0">
                        {{ data.newVerifiedInNetworkAcademies}}/{{data.currentMonthTargets.newVerifiedInNetworkAcademies}} 
                      </v-card-text>
                      <div class="radial-container">
                        <ApexChart v-if="graphOptions.radialVerifiedInNetworkAcademies" type="radialBar" :options="graphOptions.radialVerifiedInNetworkAcademies" height="300"
                          :series="data.verifiedInNetworkData.series"></ApexChart>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      outlined
                      :height="widgetHeight"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-icon class="mr-1">mdi-account-clock-outline</v-icon>
                        Current Trialing Services
                      </v-card-title>
                      <v-card-text class="text-center pb-0 mb-0">
                        {{ data.trialAcademies}}/{{data.currentMonthTargets.trialAcademies}} 
                      </v-card-text>
                      <div class="radial-container">
                        <ApexChart v-if="graphOptions.radialTrialingCustomers" type="radialBar" :options="graphOptions.radialTrialingCustomers" height="300"
                          :series="data.trialingData.series"></ApexChart>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card
                      class="pa-2"
                      outlined
                      :height="widgetHeight"
                    >
                      <ApexChart v-if="graphOptions.revenueByRegionTimeline" type="area" height="300"
                        :options="graphOptions.revenueByRegionTimeline" :series="data.timelines.revenue.series"></ApexChart>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card
                      class="pa-2"
                      outlined
                      :height="widgetHeight"
                    >
                      <ApexChart v-if="graphOptions.allCustomersByRegionTimeline" type="area" height="300"
                        :options="graphOptions.allCustomersByRegionTimeline" :series="data.timelines.allCustomers.series"></ApexChart>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card
                      class="pa-2"
                      outlined
                      :height="widgetHeight"
                    >
                      <ApexChart v-if="graphOptions.customersByRegionTimeline" type="area" height="300"
                        :options="graphOptions.customersByRegionTimeline" :series="data.timelines.newCustomers.series"></ApexChart>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :key="'data'" class="pa-2">
                <v-btn @click="reportSelection = 0" text rounded :outlined="reportSelection == 0 ? true : false">By Region</v-btn>
                <v-btn @click="reportSelection = 1" text rounded :outlined="reportSelection == 1 ? true : false">Comapre Months</v-btn>
                <div v-if="reportSelection==0" class="pa-2">
                  <v-select
                    dense
                    outlined
                    :items="regions"
                    v-model="selectedRegion"
                    @change="getRegionData()"
                    label="Select a Region"
                    :hide-details="true">
                  </v-select>
                  <div>
                    <v-skeleton-loader
                      v-if="loadingRegionData"
                      class="mx-auto"
                      type="table"
                    ></v-skeleton-loader>
                    <div v-if="regionData">
                      <v-data-table 
                        class="elevation-0" 
                        outlined 
                        :headers="tableHeaders"
                        :items="regionData" 
                        no-data-text="No Data">
                        <template v-slot:item.date="{ item }">
                          {{$d(new Date(item.date), "month_year", $i18n.locale)}}
                        </template>
                        <template v-slot:item.totalCustomers="{ item }">
                          <div class="text-center">
                            {{item.totalCustomers}} 
                            <div class="text-caption">
                              <span v-if="item.targets.valid && item.newCustomers">
                                +{{item.newCustomers}}/{{item.targets.newCustomers}}
                                <v-icon v-if="item.newInNetworkAcademies >= item.targets.newInNetworkAcademies" size="15" color="success" >mdi-check</v-icon>
                              </span>
                              <span v-else-if="item.targets.valid && item.newCustomers <= 0">0/{{item.targets.newCustomers}}</span>
                              <span v-else-if="!item.targets.valid && item.newCustomers > 0" class="success--text">+{{item.newCustomers}}</span>
                            </div>
                          </div>
                        </template>
                        <template v-slot:item.saas="{ item }">
                          ${{item.saas}}
                        </template>
                        <template v-slot:item.collectedFees="{ item }">
                          ${{item.collectedFees}}
                        </template>
                        <template v-slot:item.inNetworkAcademies="{ item }">
                          <div class="text-center">
                            {{item.inNetworkAcademies}} 
                            <div class="text-caption">
                              <span v-if="item.targets.valid && item.newInNetworkAcademies > 0">
                                +{{item.newInNetworkAcademies}}/{{item.targets.newInNetworkAcademies}}
                                <v-icon v-if="item.newInNetworkAcademies >= item.targets.newInNetworkAcademies" size="15" color="success" >mdi-check</v-icon>
                              </span>
                              <span v-else-if="item.targets.valid && item.newInNetworkAcademies <= 0">0/{{item.targets.newInNetworkAcademies}}</span>
                              <span v-else-if="!item.targets.valid && item.newInNetworkAcademies > 0" class="success--text">+{{item.newInNetworkAcademies}}</span>
                            </div>
                          </div>
                        </template>
                        <template v-slot:item.verifiedInNetworkAcademies="{ item }">
                          <div class="text-center">
                            {{item.verifiedInNetworkAcademies}} 
                            <div class="text-caption">
                              <span v-if="item.targets.valid && item.newVerifiedInNetworkAcademies > 0">
                                +{{item.newVerifiedInNetworkAcademies}}/{{item.targets.newVerifiedInNetworkAcademies}}
                                <v-icon v-if="item.newVerifiedInNetworkAcademies >= item.targets.newVerifiedInNetworkAcademies" size="15" color="success" >mdi-check</v-icon>
                              </span>
                              <span v-else-if="item.targets.valid && item.newVerifiedInNetworkAcademies <= 0">0/{{item.targets.newVerifiedInNetworkAcademies}}</span>
                              <span v-else-if="!item.targets.valid && item.newVerifiedInNetworkAcademies > 0" class="success--text">+{{item.newVerifiedInNetworkAcademies}}</span>
                            </div>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </div>
                <div v-else class="pa-2">
                  <v-card-actions>
                    <DatePicker v-if="dateOne" v-model="dateOne" :past-event="true"></DatePicker>
                    <div class="mr-2 ml-2 pl-2 pr-2">vs</div>
                    <DatePicker v-if="dateTwo" v-model="dateTwo" :past-event="true"></DatePicker>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" elevation="0" @click="compareDates()">Go</v-btn>
                  </v-card-actions>
                  <v-card-text v-if="compareLoader">
                    <div>
                      <v-progress-linear
                        indeterminate
                        color="cyan"
                      ></v-progress-linear>
                    </div>
                    <div class="text-center">
                      {{ compareLoader }}
                    </div>
                  </v-card-text>
                  <div v-else-if="graphOptions.compareGraphData">
                    <v-card elevation="0" outlined>
                      <v-card-title class="text-subtitle-1">All regions</v-card-title>
                      <div>
                        <ApexChart v-if="this.graphOptions.compareGraphData.compareAcademiesOptions" type="bar" height="300"
                          :options="this.graphOptions.compareGraphData.compareAcademiesOptions" :series="this.graphOptions.compareGraphData.compareAcademies.series"></ApexChart>
                      </div>
                      <div>
                        <ApexChart v-if="this.graphOptions.compareGraphData.compareRevenueOptions" type="bar" height="300"
                          :options="this.graphOptions.compareGraphData.compareRevenueOptions" :series="this.graphOptions.compareGraphData.compareRevenue.series"></ApexChart>
                      </div>
                    </v-card>
                    <div v-if="compareTableHeaders && graphOptions.compareGraphData.regionsData">
                      <template v-for="(i, index) in graphOptions.compareGraphData.regionsData">
                        <v-card :key="'region_data_'+index" elevation="0" outlined class="mt-2">
                          <v-card-title class="text-subtitle-1">{{ i.region }}</v-card-title>
                          <v-data-table 
                            class="elevation-0" 
                            outlined 
                            :headers="compareTableHeaders"
                            :items="i.data" 
                            no-data-text="No Data">
                            <template v-slot:item.delta="{ item }">
                              {{ item.delta }}%
                              <v-icon size="20" color="success" class="ml-1" v-if="item.delta > 0">mdi-arrow-up-drop-circle-outline</v-icon>
                              <v-icon size="20" color="error" class="ml-1" v-else-if="item.delta < 0">mdi-arrow-down-drop-circle-outline</v-icon>
                            </template>
                            <template v-slot:item.saas="{ item }">
                              ${{ item.saas }}
                            </template>
                            <template v-slot:item.collectedFees="{ item }">
                              ${{ item.collectedFees }}
                            </template>
                          </v-data-table>
                        </v-card>
                      </template>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item :key="'referrals'" class="pa-2">
                <v-select
                  dense
                  outlined
                  :items="campaigns"
                  v-model="selectedCampaign"
                  @change="getReferralData()"
                  label="Select a Campaign"
                  :hide-details="true">
                </v-select>
                <v-card-text v-if="loadingReferralData">
                  <div>
                    <v-progress-linear
                      indeterminate
                      color="cyan"
                    ></v-progress-linear>
                  </div>
                  <div class="text-center">
                    Loading data ...
                  </div>
                </v-card-text>
                <div>
                  <v-card-text v-if="availableCampaigns">
                    <v-row no-gutters v-if="referralAggregates">
                      <v-col cols="4">
                        <v-card
                          class="pa-2"
                          tile
                          elevation="0"
                        >
                          <ApexChart 
                            width="100%" height="200"
                            v-if="referralAggregates.referralsTotal > 0" type="pie" 
                            :options="referralAggregates.referralsGraph.options" 
                            :series="referralAggregates.referralsGraph.data">
                          </ApexChart>
                          <div v-else class="text-h5">
                            {{ $t('message.no_referrals') }}
                            {{referralAggregates.referralsTotal}}
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="8">
                        <v-card
                          elevation="0"
                          class="pa-2"
                          tile
                        >
                          <ApexChart 
                            v-if="referralAggregates.trendline"
                            width="100%" type="area" height="200"
                            :options="referralAggregates.trendline.options" 
                            :series="referralAggregates.trendline.data"></ApexChart>
                        </v-card>
                      </v-col>
                    </v-row>
                    <div v-if="referralAggregates && referralAggregates.tops">
                      <v-list>
                        <v-subheader>Top Performers</v-subheader>
                        <template v-for="(i, index) in referralAggregates.tops">
                          <v-list-item :key="'affilate_top__' + i._id">
                            <v-list-item-avatar>
                              <img v-if="i.image" :src="userImg(i.image)" />
                              <img v-else :src="assets('default-profile-avatar.png')" />
                            </v-list-item-avatar>
                            <v-list-item-content class="text-left">
                              <v-list-item-title>
                                {{ i.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <b>{{i.totalReferrals}}</b> referrals
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <b>{{i.totalConverted}}</b> converted
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content class="text-left">
                              <v-list-item-title>
                                {{i.campaign}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="index + 1 < referralAggregates.tops.length" :key="index"></v-divider>
                        </template>
                      </v-list>
                    </div>
                    <template v-for="(i, index) in availableCampaigns">
                      <v-card :key="'campaign_data_'+index" elevation="0" outlined class="mt-2">
                        <v-card-title class="text-subtitle-1">{{ i }}</v-card-title>
                        <v-card-text>
                          <v-data-table 
                            item-key="_id" 
                            :headers="affiliateHeaders"
                            :items="campaignsData[i]" 
                            class="elevation-0" 
                            no-data-text="No Data">
                            <template v-slot:item.name="{ item }">
                              <v-avatar class="mr-1" size="20">
                                <img v-if="item.image" :src="userImg(item.image)" />
                                <img v-else :src="assets() + '/default-profile-avatar.png'" />
                              </v-avatar>{{ item.name }}
                            </template>
                            <template v-slot:item.email="{ item }">
                              <v-btn text @click="copyToClipboard(item.email)" small>
                                {{ item.email }}
                              </v-btn>
                            </template>
                            <template v-slot:item.referrals="{ item }">
                              {{item.converted}}/{{ item.referrals }}
                            </template>
                            <template v-slot:item.createdAt="{ item }">
                              <span v-if="item.createdAt">{{
                                $d(new Date(item.createdAt), "month_day_year", $i18n.locale)
                                }}</span>
                            </template>
                            <template v-slot:item.totalPaid="{ item }">
                              ${{ item.totalPaid }}
                            </template>
                            <template v-slot:item.active="{ item }">
                              <v-icon size="20" color="success" v-if="item.active">mdi-check-circle-outline</v-icon>
                              <v-icon size="20" color="error" v-else-if="!item.active && item.expirationDate">mdi-timer-sand-complete</v-icon>
                              <v-icon size="20" color="error" v-else>mdi-close-circle-outline</v-icon>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-card-text>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </div>
      <div class="text-right text-caption mr-1">
        *Confidantial information, developed for the MMA.INC executive team
      </div>
      <v-dialog v-model="countriesDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card outlined v-if="data && data.clientDetailsByRegion">
          <v-toolbar dense color="primary">
            Customers by Region as of today
            <v-spacer></v-spacer>
            <v-btn icon @click="countriesDialog = false">
              <v-icon size="20">mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-2 text-center">
            <template v-for="(c, index) in Object.keys(data.clientDetailsByRegion)">
              <v-expansion-panels :key="index+'_'+c" class="mt-2" flat>
                <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
                  <v-expansion-panel-header>
                    {{ c == 'Trialing' ? 'New Customers Trialing' : c }} ({{ data.clientDetailsByRegion[c].length }})
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <template v-for="(a, index2) in data.clientDetailsByRegion[c]">
                        <v-list-item :key="a.managedAcademyId+'_'+index2">
                          <v-list-item-avatar>
                            <img :src="userFile(a.image)"/>
                          </v-list-item-avatar>
                          <v-list-item-content class="text-left">
                            <v-list-item-title>
                              {{ a.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ a.address }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                              <CountryFlagDisplay size="30" :avatar="true" :countryCode="a.country"></CountryFlagDisplay>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-menu offset-y small>
                              <template v-slot:activator="{ on }">
                                <v-btn elevation="0" v-on="on" small slot="activator" >
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item v-if="a.stripeAccountId" @click.stop="gotToAction('stripe', a)">
                                  <v-list-item-title>
                                    Go To Stripe Account
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.stop="gotToAction('hub', a)">
                                  <v-list-item-title>
                                    Go To Admin Hub Entry
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.stop="gotToAction('academy', a)">
                                  <v-list-item-title>
                                    Go to Academy on BJJLINK
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.stop="gotToAction('email', a)">
                                  <v-list-item-title>
                                    Copy Academy Contact email
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.stop="gotToAction('managedacademy', a)">
                                  <v-list-item-title>
                                    Copy Managed Academy Id
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click.stop="gotToAction('location', a)">
                                  <v-list-item-title>
                                    Go To Location
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small elevation="0" @click="countriesDialog=false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </template>

  <script>
  import IconMaster from "@/components/displays/icons/IconMaster.vue";
  import BjjlinkAdminService from "@/services/BjjlinkAdminService";
  import ApexChart from 'vue-apexcharts';
  import CountryFlagDisplay from "@/components/displays/CountryFlagDisplay.vue";
  import DatePicker from "@/components/inputs/DateTimePicker2.vue";

  export default {
    components: {
      ApexChart,
      IconMaster,
      CountryFlagDisplay,
      DatePicker
    },
    data() {
      return {
        invalidUser: true,
        loadingHome: true,
        widgetHeight: 350,
        data: null,
        graphOptions: {},
        countriesDialog: false,
        dateOne: null,
        dateTwo: null,
        compareLoader: null,
        selectedCampaign: 'All',
        availableCampaigns: null,
        campaignsData: {},
        campaigns: ['All'],
        allAffiliatesData: [],
        referralAggregates: null,
        loadingReferralData: false,
        activeTab: 0,
        regions: [],
        selectedRegion: null,
        reportSelection: 0,
        regionData: null,
        loadingRegionData: false,
        tableHeaders: [
          { text: 'Month', value: "date" },
          { text: 'Customers', value: "totalCustomers", align:'center', sortable: false },
          { text: 'SaaS', value: "saas", sortable: false },
          { text: 'Fees', value: "collectedFees", sortable: false },
          { text: 'InNetwork', value: "inNetworkAcademies", align:'center', sortable: false },
          { text: 'Verified', value: "verifiedInNetworkAcademies", align:'center', sortable: false },
          { text: 'Trialing', value: "trialAcademies", align:'center', sortable: false },
        ],
        affiliateHeaders: [
          { text: "Name", value: "name" },
          { text: "Email", value: "email" },
          { text: "Since", value: "createdAt" },
          { text: "Referrals", value: "referrals" },
          { text: "Paid", value: "totalPaid" },
          { text: "Interactions", value: "interactions" },
          { text: "Active", value: "active" },
        ],
        compareTableHeaders: null
      };
    },

    created() {
      this.loadData();
      const dateOne = new Date();
      dateOne.setMonth(dateOne.getMonth() - 1);
      dateOne.setDate(1)
      const dateTwo = new Date();
      dateTwo.setDate(1)
      this.dateOne = dateOne;
      this.dateTwo = dateTwo;
    },
    methods: {
      gotToAction(action, data) {
        if (action == 'stripe') {
          window.open('https://dashboard.stripe.com/connect/accounts/'+data.stripeAccountId+'/activity')
        } else if (action == 'hub') {
          window.open('https://bjjlink.com/admin/secure/member/'+data.userId)
        } else if (action == 'managedacademy') {
          this.copyToClipboard(data.managedAcademyId);
        } else if (action == 'academy') {
          window.open('https://bjjlink.com/secure/academy/'+data.academyId)
        } else if (action == 'email') {
          this.copyToClipboard(data.contact);
        } else if (action == 'location') {
          this.goToDirections(data.address)
        }
      },
      async getReferralData(init) {
        this.loadingReferralData = true;
        this.availableCampaigns = null;
        this.campaignsData = {};
        if (init) {
          let tmp = await BjjlinkAdminService.getReferralData();
          this.campaigns = this.campaigns.concat(tmp.campaigns);
          this.allAffiliatesData = tmp.data;
        }
        //Loop over campaigns to be able to display the data nicely
        for (const i of this.allAffiliatesData) {
          const campaign = i.campaign;
          if (this.selectedCampaign == 'All' || this.selectedCampaign == campaign) {
            if (!this.campaignsData[campaign])
              this.campaignsData[campaign] = [];
            this.campaignsData[campaign].push(i);
          }
        }
        this.availableCampaigns = Object.keys(this.campaignsData);
        
        let tmp = await BjjlinkAdminService.getReferralAggregates({ campaign: this.selectedCampaign });
        this.referralAggregates = tmp;
        this.referralAggregates.referralsGraph = {
          data: [tmp.referralsConverted, tmp.referralsPending],
          options: {
            legend: {
              show: false
            },
            stroke: { show: false },
            theme: {
              mode: this.isDarkTheme() ? 'dark' : 'light',
            },
            chart: { background: 'none' },
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: -10
                }
              }
            },
            colors: [this.themeColor('success'), this.themeColor('error')],
            labels: ["Converted", "Pending"]
          }
        };
        //Set up the dates:
          const categories = [];
          for (const i of tmp.trendline.dates) {
            const d = new Date();
            d.setDate(i.day);
            d.setMonth(i.month);
            d.setFullYear(i.year);
            d.setHours(0);
            d.setMinutes(0);
            categories.push(d)
          }
        this.referralAggregates.tops = tmp.topAffiliates && tmp.topAffiliates.length ? tmp.topAffiliates : null;
        this.referralAggregates.trendline = {
          data:[
            {
              name: tmp.trendline.seriesLabels[0],
              data: tmp.trendline.seriesData[0]
            },
            {
              name: tmp.trendline.seriesLabels[1],
              data: tmp.trendline.seriesData[1]
            },
          ],
          options: {
            colors: [this.themeColor('primary'), this.themeColor('orange')],
            xaxis: {
              categories: categories,
              labels: {
                show: false,
              }
            },
            yaxis: {
              labels: {
                show: false,
              }
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy'
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
                      curve: 'smooth'
            },
            theme: {
              mode: this.isDarkTheme() ? 'dark' : 'light',
            },
            chart: {
              toolbar: {
                show: false
              },
              background: 'none'
            },
            legend:{
              position: 'bottom'
            },
            grid: {
              borderColor: this.themeColor('gray'),
              yaxis: {
                lines: {
                  show: false  //or just here to disable only x axis grids
                }
              },
            },
          }
        };
        this.loadingReferralData = false;
      },
      async compareDates() {
        if (this.dateOne > this.dateTwo)
          return this.showAlert("error", "First date needs to be earlier than second date");
        const date1String = this.$d(this.dateOne, "month_year", this.$i18n.locale);
        const date2String = this.$d(this.dateTwo, "month_year", this.$i18n.locale);

        this.compareLoader = 'Crunching the numbers for ' + date1String + ' against ' + date2String;

        let tmp = await BjjlinkAdminService.compareMonths({ date1: this.dateOne, date2: this.dateTwo, date1String:date1String, date2String:date2String  });
        this.graphOptions.compareGraphData = tmp.data.data;
        this.compareLoader = null;
        this.graphOptions.compareGraphData.compareAcademiesOptions = {
          chart: {
            background: 'none',
            stacked: true,
            toolbar: {
              show: false
            },
          },
          colors: ['#008FFB', '#FF4560'],
          plotOptions: {
            bar: {
              borderRadius: 5,
              borderRadiusApplication: 'end', // 'around', 'end'
              borderRadiusWhenStacked: 'all', // 'all', 'last'
              horizontal: true,
              barHeight: '80%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          grid: {
            show: false,
          },
          yaxis: {
            stepSize: 1
          },
          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                return val
              }
            },
            y: {
              formatter: function (val) {
                return Math.abs(val)
              }
            }
          },
          xaxis: {
            categories: this.graphOptions.compareGraphData.compareAcademies.categories,
            title: {
              show: false
            },
            labels: {
              formatter: function (val) {
                return Math.abs(Math.round(val))
              }
            }
          },
        };

        this.graphOptions.compareGraphData.compareRevenueOptions = {
          chart: {
            background: 'none',
            stacked: true,
            toolbar: {
              show: false
            },
          },
          colors: ['#008FFB', '#FF4560'],
          plotOptions: {
            bar: {
              borderRadius: 5,
              borderRadiusApplication: 'end', // 'around', 'end'
              borderRadiusWhenStacked: 'all', // 'all', 'last'
              horizontal: true,
              barHeight: '80%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          grid: {
            show: false,
          },
          yaxis: {
            stepSize: 1
          },
          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                return val
              }
            },
            y: {
              formatter: function (val) {
                return '$'+Math.abs(val)
              }
            }
          },
          xaxis: {
            categories: this.graphOptions.compareGraphData.compareRevenue.categories,
            title: {
              show: false
            },
            labels: {
              formatter: function (val) {
                return '$'+Math.abs(Math.round(val))
              }
            }
          },
        };

        //Now build the tables
        this.compareTableHeaders = [
          { text: 'Metric', value: "metric", sortable: false },
          { text: date1String, value: "month1", sortable: false },
          { text: date2String, value: "month2", sortable: false },
          { text: 'Change', value: "delta", sortable: false },
        ];
      },
      async getRegionData(){
        this.loadingRegionData = true;
        let tmp = await BjjlinkAdminService.getRegionData({region: this.selectedRegion});
        this.regionData = tmp.data;
        this.loadingRegionData = false;
      },
      async getAvalableRegions(){
        let tmp = await BjjlinkAdminService.getAvailableRegions();
        this.regions = tmp.regions;
        this.regions.unshift('All');
      },
      async loadData() {
        try{
          await BjjlinkAdminService.refreshData();
          let filters = {region:'all'};
          let tmp = await BjjlinkAdminService.getHomeData(filters);
          this.data = tmp.data;
          this.loadingHome = false;
          this.invalidUser = false;

          //Start setting up all graphs
          this.buildRegionsGraph();
          this.buildRadialGraphs();
          this.buildRevenueByTypeGraph();
          this.buildRevenueByRegionTimeline();
          this.buildNewCustomersByRegionTimeline();
          this.buildAllCustomersByRegionTimeline();
          this.getAvalableRegions();
        }catch(err){
          console.log(err);
          this.loadingHome = false;
        }
      },
      buildNewCustomersByRegionTimeline(){
        const data = this.data;
        this.graphOptions.customersByRegionTimeline = {
          xaxis: {
            categories: data.timelines.categories,
            type: 'datetime',
            labels: {
              show: true,
              style: {
                colors: this.themeColor('title')
              },
            }
          },
          yaxis: {
            title: {
              text: 'New Customers'
            },
            labels: {
              show: true,
              style: {
                colors: this.themeColor('title')
              },
            }
          },
          tooltip: {
            x: {
              format: 'MM/yy'
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth'
          },
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          chart: {
            toolbar: {
              show: false
            },
            background: 'none',
            stacked: true,
          },
          legend:{
            position: 'bottom'
          },
          grid: {
            show: false
          },
        }
      },
      buildAllCustomersByRegionTimeline(){
        const data = this.data;
        this.graphOptions.allCustomersByRegionTimeline = {
          xaxis: {
            categories: data.timelines.categories,
            type: 'datetime',
            labels: {
              show: true,
              style: {
                colors: this.themeColor('title')
              },
            }
          },
          yaxis: {
            title: {
              text: 'All Customers'
            },
            labels: {
              show: true,
              style: {
                colors: this.themeColor('title')
              },
            }
          },
          tooltip: {
            x: {
              format: 'MM/yy'
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth'
          },
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          chart: {
            toolbar: {
              show: false
            },
            background: 'none',
            stacked: true,
          },
          legend:{
            position: 'bottom'
          },
          grid: {
            show: false
          },
        }
      },
      buildRevenueByRegionTimeline(){
        const data = this.data;
        this.graphOptions.revenueByRegionTimeline = {
          xaxis: {
            categories: data.timelines.categories,
            type: 'datetime',
            labels: {
              show: true,
              style: {
                colors: this.themeColor('title')
              },
            }
          },
          yaxis: {
            title: {
              text: 'Combined Revenue ($)'
            },
            labels: {
              show: true,
              style: {
                colors: this.themeColor('title')
              },
            }
          },
          tooltip: {
            x: {
              format: 'MM/yy'
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth'
          },
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          chart: {
            toolbar: {
              show: false
            },
            background: 'none',
            stacked: true,
          },
          legend:{
            position: 'bottom'
          },
          grid: {
            show: false
          },
        }
      },
      buildRadialGraphs(){
        //this.data.newCustomers = 30;
        this.graphOptions.radialCustomers = this.getRadialGraphOptions(['New Customers']);
        this.graphOptions.radialInNetworkAcademies = this.getRadialGraphOptions(['New InNetwork Academies']);
        this.graphOptions.radialVerifiedInNetworkAcademies = this.getRadialGraphOptions(['New Verified InNetwork Academies']);
        this.graphOptions.radialTrialingCustomers = this.getRadialGraphOptions(['New Trialing Services']);
      },
      getRadialGraphOptions(labels){
        return {
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          chart: {
            background: 'none', 
            toolbar: {
              show: false
            }
          },
          tooltip: {
            show: true,
          },
          colors: ['#00E396'],
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '16px',
                  color: undefined,
                  offsetY: 120
                },
                value: {
                  offsetY: 76,
                  fontSize: '15px',
                  color: undefined,
                  formatter: function (val) {
                    return val + "%";
                  }
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91]
            },
          },
          stroke: {
            dashArray: 4
          },
          labels: labels,
        }
      },
      buildRevenueByTypeGraph(){
        this.graphOptions.revenueGraph = {
          plotOptions: {
            bar: {
              columnWidth: '60%'
            }
          },
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          chart: {
            background: 'none', 
            toolbar: {
              show: false
            }
          },
          grid:{
            show: false
          },
          colors: ['#00E396'],
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ['Actual', 'Expected'],
            markers: {
              fillColors: ['#00E396', '#775DD0']
            }
          }
        }
      },
      buildRegionsGraph(){
        const clientsByRegion = this.data.clientsByRegion;
        this.graphOptions.regionsGraph = {
          legend:{
            position: 'bottom'
          },
          theme: {
            mode: this.isDarkTheme() ? 'dark' : 'light',
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                colors: this.themeColor('title')
              },
            }
          },
          chart: {background: 'none'},
          colors: [
            this.themeColor('blue'),
            this.themeColor('success'),
            this.themeColor('orange'),
            this.themeColor('accent')],
          labels: clientsByRegion.labels
        };
      }
    },
  };
  </script>

  <style scoped>
  .radial-container{
    margin-top:-25px
  }
  </style>
