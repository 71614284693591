<template>
  <v-container class="pa-0" v-if="schedule" grid-list-md text-xs-center>

        <template v-for="(d, dayIndex) in days">
            <v-list
              subheader
              two-line
              :key="'day'+dayIndex"
            >
              <v-subheader class="pa-0" v-if="schedule[d].length>0"><span class="primary--text text-subtitle-1">{{d | capitalize}}</span></v-subheader>
              <div v-if="schedule[d].length>0">
                <template v-for="(c, hourIndex) in schedule[d]">
                  <v-list-item :key="d+hourIndex" @click.stop="doNothing()" class="pl-0 pr-0">
                    <v-list-item-content>
                      <v-list-item-title>{{c.name}}</v-list-item-title>
                      <v-list-item-subtitle v-if="c.professorUser">{{displayProfessorName(c.professorUser)}}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{$d(numberToDate(c.start), "time", $i18n.locale)}} - {{$d(numberToDate(c.end), "time", $i18n.locale)}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </div>
          </v-list>
      </template>
  </v-container>
</template>
<script>

export default {
  data() {
    return {
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ],
      hours: this.initializeHours()
    };
  },
  components: {},
  props: {
    schedule: null,
    professors: null
  },
  created(){
  },
  mounted() {
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    displayProfessorName(id){
      if(id && this.professors && this.professors[id]){
        return this.professors[id].displayName;
      }
    },
    initializeHours(){
      var hours = [];
      for(var i = 6; i < 24; i+= 0.25){
        var display = "";
        const r = i % 1;
        if(r == 0.25)
          display = Math.round(i-r)+':15';
        else if(r ==  0.5)
          display = Math.round(i-r)+':30';
        else if(r ==  0.75)
          display = Math.round(i-r)+':45';
        else
          display = Math.round(i)+':00';
        hours.push({display: display, value: i});
      }
      return hours;
    },
    doNothing(){},
    numberToString(time) {
      const split = time.toString().split(".");
      if (split.length > 1){
        if(split[1] == 25)
          return split[0] + ":15";
        else if(split[1] == 5)
          return split[0] + ":30";
        else if(split[1] == 75)
          return split[0] + ":45";
        else
          return split[0] + ":00";
      }
      else return time + ":00";
    },
    numberToDate(time) {
      const split = time.toString().split(".");
      let hoursString = split[0];
      let minutesString = "00";
      if (split.length > 1){
        if(split[1] == 25){
          hoursString = split[0];
          minutesString = "15";
        }else if(split[1] == 5){
          hoursString = split[0];
          minutesString = "30";
        }else if(split[1] == 75){
          hoursString = split[0];
          minutesString = "45";
        }else{
          hoursString = split[0];
          minutesString = "00";
        }
      }
      const d = new Date();
      d.setHours(hoursString);
      d.setMinutes(minutesString);
      d.setSeconds(0);
      return d;
    }
  }
};
</script>

<style scoped>
</style>
